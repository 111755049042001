import { FC, useEffect } from 'react'

import { authorizeByTokenAtMailruAction } from 'actions/system/authorizeByTokenAtMailruAction'
import { indexPath } from 'components/paths'
import { findMailRuToken } from 'components/system/authorization/findMailRuToken'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'

export const LoveMailRuToken: FC<{
  onFinished: (value: boolean) => void
}> = ({ onFinished }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const authorize = async () => {
      const token = findMailRuToken()

      if (token) {
        const response = await dispatch(authorizeByTokenAtMailruAction(token))

        if ('error' in response) {
          console.error(response)
        } else {
          /** отправляем в корень, пока не показываем роуты, чтобы не было 404 */
          dispatch(push(indexPath))
        }
      }
      /** показываем роуты */
      onFinished(true)
    }

    authorize().catch(console.error)
  }, [dispatch, onFinished])

  return null
}

export default LoveMailRuToken
